.main {
  position: relative;
  overflow: hidden;
  padding: 128px 0;
  h1 {
    font-size: 60px;
    font-family: 'Georama';
    font-weight: 900;
    color: #ffffff;
    position: relative;
    z-index: 1;
  }
  h3 {
    color: #B8F0FF;
    font-family: 'Raleway';
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 50px;
  }
  p {
    font-size: 20px;
    font-family: 'Raleway';
    color: #ffffff;
    font-weight: 600;
  }
}

.wrap {
  padding-left: 15%;
  text-align: left;
  background-color: #002853;
  padding-top: 10px;
  padding-bottom: 200px;
}

.hWrap {
  position: relative;
  text-align: left;
  .dots {
    position: absolute;
    bottom: -20px;
    left: -100px;
  }
}
.textWrap {
  max-width: 50%
}

.box {
  position: absolute;
  right: -10%;
  top: 30%;
  width: 40%;
  height: 40%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg) ;

  .innerBox {
    width: 80%;
    aspect-ratio: 1 / 1;
    z-index: 3;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3);
    overflow: hidden;
    div {
      height: 160%;
      width: 160%;
      transform: rotate(-45deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: -15%;
      top: -30%;
    }
  }
  .firstBox {
    width: 90%;
    aspect-ratio: 1 / 1;
    z-index: 2;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)
  }
  .outerBox {
    width: 100%;
    aspect-ratio: 1 / 1;
    z-index: 1;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)

  }
}

.topWaves {
  position: absolute;
  top: 14px;
  left: 0
}
.bottomWaves {
  position: absolute;
  bottom: 14px;
  right: 0;
}

@media screen and (max-width: 992px) { 
  .logo {
    max-width: 50%;
    margin-bottom: 0;
  }
  .main {
    padding-bottom: 130px;
    padding-top: 50px;
    h1 {
      font-size: 40px;
    }
  }
  .wrap {
    padding: 32px
  }
  .hWrap {
    .dots {
      width: 80px;
      top: 26px;
      left: -54px;
    }
  }
  .textWrap {
    max-width: 100%;
  }
  .box {
    display: none;
  }
}
