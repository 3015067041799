.main {
  position: relative;
  max-width: 1920px !important;
  margin: 0 auto;
  img {
    object-fit: contain;
  }
}

.container {
  background-color: #002853;

}
.imgWrap {
  position: relative;
  height: 10px;
}

.topWaves {
  position: absolute;
  width: 100%;
  bottom: 80%;
  left: 0
}
.wrap {
  display: flex;
  padding-left: 12% ;
  margin-top: -20px;
  z-index: 1;
  position: relative;
  padding-bottom: 30px;
  text-align: left;
  h2 {
    color: #F4C012;
    padding-left: 16px;
    font-size: 25px;
    font-family: 'Georama';
    text-transform: uppercase;
    cursor: pointer;
  }
  span {
    color: #F4C012;
    font-size: 25px;
    font-family: 'Raleway';
    font-weight: 700;
  }
  // li {
  //   color: #ffffff;
  //   font-size: 25px;
  //   font-family: 'Raleway';
  //   font-weight: 500;
  //   position: relative;
    
  // }
  // ul li::before {
  //   content: "\2022";
  //   content: "\2022";
  //   color: #F4C012;
  //   font-weight: bold;
  //   display: inline-block;
  //   margin-left: -25px;
  //   line-height: 0;
  //   font-size: 42px;
  //   position: absolute;
  //   top: 16px;
  //   left: -2px;
  // }
}
.textWrap{

}

.popup {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
}
.popupWrap {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  max-height: 1000px;
  margin: auto;
  background-color: #ffffff;
  padding: 12px 12px 22px 12px;
  text-align: left;
  box-shadow: 0 4px 8px 1px;
  border-radius: 8px;
  overflow: auto;
  h3 {
    margin: 4px 0;
  }
  p {
    margin: 4px 0;
  }
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 40px;
  cursor: pointer;

}

@media screen and (max-width: 992px) {
  .wrap {
    flex-direction: column;
    padding: 8px;
    img {
      max-width: 50%;
    }
  } 
  .textWrap {
    padding-left: 0;
  }
}
