.main {
  position: relative;
  overflow: hidden;
  padding-bottom: 320px;
  h1 {
    font-size: 60px;
    font-family: 'Georama';
    font-weight: 900;
    color: #002853;
  }
  h3 {
    color: #002853;
    font-family: 'Raleway';
    font-size: 25px;
    font-weight: 700;
  }
  h4 {
    font-size: 25px;
    font-family: 'Raleway';
    color: #002853;
    font-weight: 700;
  }
  p {
    font-size: 25px;
    font-family: 'Raleway';
    color: #2e2e2e;
    font-weight: 400;
  }
}
.wrap {
  padding-left: 15%;
  text-align: left;
}
.logo { 
  width: 270px;
  margin-top: 30px;
  margin-bottom: 240px;
}
.squares {
  position: absolute;
  left: 15%;
  top: 30%;
  z-index: -1;
}

.text{
  max-width: 45%;
}
.hWrap {
  position: relative;
  .dots {
    position: absolute;
    top: 40px;
    left: -100px;
    z-index: -1;
  }
}

.box {
  position: absolute;
  right: -10%;
  top: 20%;
  width: 50%;
  height: 50%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg) ;

  .innerBox {
    width: 80%;
    aspect-ratio: 1 / 1;
    z-index: 3;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3);
    overflow: hidden;
    div {
      height: 175%;
      width: 175%;
      transform: rotate(-45deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: -15%;
      top: -30%;
    }
  }
  .firstBox {
    width: 90%;
    aspect-ratio: 1 / 1;
    z-index: 2;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)
  }
  .outerBox {
    width: 100%;
    aspect-ratio: 1 / 1;
    z-index: 1;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)

  }
}

@media screen and (max-width: 992px) { 
  .logo {
    max-width: 50%;
    margin-bottom: 0;
  }
  .main {
    padding-bottom: 100px;
    h1 {
      font-size: 40px;
    }
  }
  .wrap {
    padding: 32px
  }
  .hWrap {
    .dots {
      width: 80px;
      top: 26px;
      left: -54px;
    }
  }
  .text {
    max-width: 100%;
  }
  .squares {
    display: none;
  }
  .box {
    display: none;
  }
}
