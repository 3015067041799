.main {
  position: relative;
  overflow: hidden;
  padding-bottom: 320px;
  h1 {
    font-size: 60px;
    font-family: 'Georama';
    font-weight: 900;
    color: #002853;
  }
  h3 {
    color: #002853;
    font-family: 'Raleway';
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
  h4 {
    color: #002853;
    font-size: 23px;
    font-weight: 900;
    font-family: 'Georama';
    text-transform: uppercase;
    text-align: center;
    margin: 0;
  }
  h5 {
    color: #002853;
    font-size: 23px;
    font-weight: 300;
    font-style: italic;
    margin: 0;
    text-align: center;
  }
  h6 {
    color: #002853;
    font-size: 16px;
    font-family: 'Raleway';
    font-weight: 400;
    margin: 12px 0;
  }
  p {
    font-size: 20px;
    font-family: 'Raleway';
    color: #002853;
    font-weight: 400;
    margin: 0;
  }
}
.wrap {
  padding: 0 15%;
  text-align: left;
}
.hWrap {
  position: relative;
  text-align: left;
  .dots {
    position: absolute;
    bottom: -20px;
    left: -100px;
    z-index: -1;
  }
}

.containerBoxGreen {
  border: 4px solid rgba(111, 191, 156, 0.30);
  border-radius: 15px;
  margin-bottom: 30px;
  padding: 24px;
  max-width: 65%;
  background: rgba(255, 255, 255, 0.95);
  position: relative;
  h3 {
    color: #5DB890
  }
}
.containerBoxGray {
  border: 4px solid rgba(0, 40, 83, 0.30);
  border-radius: 15px;
  padding: 24px;
  max-width: 65%;
  background: rgba(255, 255, 255, 0.95);
  position: relative;
}
.greenSquare {
  position: absolute;
  height: 120px;
  width: 120px;
  border-radius: 16px;
  background: #5DB890;
  top: -70px;
  right: -70px;
  transform: rotate(-25deg);
  z-index: -1;
}
.blueSquare {
  position: absolute;
  height: 120px;
  width: 120px;
  border-radius: 16px;
  background: #002853;
  bottom: -50px;
  left: -70px;
  transform: rotate(-25deg);
  z-index: -1;
}

.box {
  position: absolute;
  right: 2%;
  top: 5%;
  width: 33%;
  height: 33%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg) ;

  .innerBox {
    width: 80%;
    aspect-ratio: 1 / 1;
    z-index: 3;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3);
    overflow: hidden;
    div {
      height: 160%;
      width: 132%;
      transform: rotate(-45deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: -15%;
      top: -30%;
    }
  }
  .firstBox {
    width: 90%;
    aspect-ratio: 1 / 1;
    z-index: 2;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)
  }
  .outerBox {
    width: 100%;
    aspect-ratio: 1 / 1;
    z-index: 1;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)

  }
}

.teamWrapDesktop, .teamWrapMobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .memberWrap {
    width: 100%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
    position: relative;
  }
  .memberImgWrap {
    width: 50%;
    aspect-ratio: 1 / 1;
    border: 2px solid rgba(76, 113, 186, 0.3);
    border-radius: 22px;
    transform: rotate(45deg);
    overflow: hidden;
    margin-bottom: 50px;
    position: relative;
    box-shadow: 0px 0px 2px 1px #dedede;
  }
  .memberImg {
    width: 140%;
    height: 140%;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: rotate(-45deg);
    position: relative;    
    top: -40px;
    left: -40px;
  }
  .memberText {
    border: 4px solid rgba(0, 40, 83, 0.30);
    border-radius: 15px;
    padding: 16px;
    height: auto;
    overflow: hidden;
    position: absolute;
    top: 100%;
    background-color: #ffffff;
    z-index: 2;
    transition: max-height 0.7s, z-index 1s;
    max-height: 55px;
    &:hover {
      max-height: 600px;
      z-index: 5;
    }
  }
}

.dot {
  margin-left: -20px;
  margin-right: 8px;
  margin-bottom: 2px;
}
.listWrap {
  padding-left: 16px;
}

.teamWrapMobile {
  display: none;
}

@media screen and (max-width: 992px) { 
  .logo {
    max-width: 50%;
    margin-bottom: 0;
  }
  .main {
    padding-bottom: 230px;
    padding-top: 50px;
    h1 {
      font-size: 40px;
    }
  }
  .wrap {
    padding: 32px
  }
  .hWrap {
    margin-top: 80px;
    .dots {
      width: 80px;
      top: 26px;
      left: -54px;
    }
  }
  .textWrap {
    max-width: 100%;
  }
  .box {
    display: none;
  }
  .containerBoxGray, .containerBoxGreen {
    max-width: 100%;
  }
  .teamWrapDesktop {
    display: none;
  }
  .teamWrapMobile {
    display: block;
    .memberWrap {
      margin: 50px auto;
      width: 100%;
      max-width: 400px;
      h4 {
        font-size: 18px;
      }
      h5 {
        font-size: 18px;
      }
      h6 {
        font-size: 14px;
      }
    }
    .memberText {
      max-height: 40px;
    }
    .dot {
      margin-bottom: -3px;
    }
  }
  .carousel {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
  }
  .carouselDot {
    width: 12px;
    height: 12px;
    background-color: #CCDEE3;
    border-radius: 100%;
    margin-right: 20px;
  }
  .carouselDotActive {
  background-color: #002853;
  }
}
