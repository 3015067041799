.main {
  position: relative;
  overflow: hidden;
  padding: 128px 0;
  h1 {
    font-size: 60px;
    font-family: 'Georama';
    font-weight: 900;
    color: #ffffff;
  }
  h4 {
    font-size: 25px;
    font-family: 'Raleway';
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    margin-top: 16px;
  }
  h5 {
    font-family: 'Raleway';
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
  }
  h6 {
    font-family: 'Raleway';
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    color: #77eebf;
    margin: 0;
  }
  p {
    font-family: 'Raleway';
    color: #ffffff;
    font-size: 22px;
    font-style: italic;
    font-weight: 700;
    margin: 0 0 12px 0;

  }
}
.wrap {
  padding-left: 15%;
  text-align: left;
  background-color: #002853;
  padding-top: 10px;
  padding-bottom: 200px;
}
.hWrap {
  position: relative;
  h1 {
    z-index: 1;
    position: relative;
  }
  .dots {
    position: absolute;
    bottom: -10px;
    left: -100px;
  }
}
.textWrap {
  max-width: 45%
}
.topWaves {
  position: absolute;
  top: 13px;
  left: 0
}
.bottomWaves {
  position: absolute;
  bottom: 13px;
  right: 0;
}
.footerBox {
  background-color: #5DB890;
  border-radius: 15px;
  padding: 20px 30px;
  position: relative;
  display: inline-block;
  padding-right: 80px;
  margin-top: 40px;
  img {
    position: absolute;
    right: -65px;
    top: -23px;
    height: 140px;
  }
}
.dot {
  margin-left: -20px;
  margin-right: 8px;
  margin-bottom: 2px;
}
.box {
  position: absolute;
  right: 3%;
  top: 31%;
  width: 40%;
  height: 40%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg) ;

  .innerBox {
    width: 80%;
    aspect-ratio: 1 / 1;
    z-index: 3;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3);
    overflow: hidden;
    div {
      height: 160%;
      width: 160%;
      transform: rotate(-45deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: -15%;
      top: -30%;
    }
  }
  .firstBox {
    width: 90%;
    aspect-ratio: 1 / 1;
    z-index: 2;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)
  }
  .outerBox {
    width: 100%;
    aspect-ratio: 1 / 1;
    z-index: 1;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)

  }
}
@media screen and (max-width: 992px) { 
  .logo {
    max-width: 50%;
    margin-bottom: 0;
  }
  .main {
    padding-bottom: 150px;
    padding-top: 50px;
    h1 {
      font-size: 40px;
    }
  }
  .wrap {
    padding: 32px
  }
  .hWrap {
    .dots {
      width: 80px;
      top: 26px;
      left: -54px;
    }
  }
  .textWrap {
    max-width: 100%;
  }
  .squares {
    display: none;
  }
  .box {
    display: none;
  }
  .footerBox {
    padding: 14px;
    width: 90%;
    max-width: 500px;
    p {
      font-size: 14px;
    }
    img {
      right: -45px;
      top: -20px;
      height: 100px;
    }
  }
}
