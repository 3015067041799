.main {
  a {
    text-decoration: none;
    color: #ffffff;
    font-family: Georama;
    font-size: 18px;
    font-style: normal;
    text-transform: uppercase;
    margin: 0 24px;
    text-align: left;
  }
  img {
    margin-right: auto;
    height: 50px;
  }
}
.container {
  max-width: 1920px !important;
  padding-left: 12% !important;
}
.menuButton {
  margin-left: auto;
}

.menu {
  display: none;
  position: absolute;
  right: -25px;
  top: 0;
  z-index: 5;
  color: #ffffff;
  flex-direction: column;
  padding: 24px;
  a {
    text-decoration: none;
    font-size: 14px;
    font-family: 'Georama';
    text-align: right; 
    margin: 0;

  }
}
.open {
  display: flex;
}
.separator {
  height: 2px;
  width: 150px;
  background-color: #D70E90;
  margin-bottom: 20px;
}
.square {
  width: 600px;
  object-fit: contain;
  height: 500px !important;
  position: absolute;
  top: 0;
  right: -130px;
  z-index: -1;
}

.menuButtonOpen {
  margin-left: auto;
  margin-top: -8px;
}