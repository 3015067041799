.main {
  position: relative;
  overflow: hidden;
  padding-bottom: 320px;
  h1 {
    font-size: 60px;
    font-family: 'Georama';
    font-weight: 900;
    color: #002853;
  }
  h3 {
    color: #002853;
    font-family: 'Raleway';
    font-size: 25px;
    font-weight: 700;
    margin: 0;
    cursor: pointer;
  }
  h4 {
    font-size: 25px;
    font-family: 'Raleway';
    color: #002853;
    font-weight: 700;
  }
  p {
    font-size: 25px;
    font-family: 'Raleway';
    color: #2e2e2e;
    font-weight: 400;
  }
}
.wrap {
  padding: 0 15%;
  text-align: left;
}
.hWrap {
  position: relative;
  text-align: left;
  .dots {
    position: absolute;
    bottom: -20px;
    left: -100px;
    z-index: -1;
  }
}
.coursesWrap {
  display: flex;
  padding-top: 175px;
}

.curso {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  h3 {
    max-width: 360px;
    margin-top: 180px;
  }
  &:nth-child(even) {
    margin-top: 70px;
  }
}

.squares {
  position: absolute;
  right: 20%;
  top: 25%;
  z-index: -1;
}

.box {
  position: relative;
  // right: -10%;
  // top: 20%;
  width: 60%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg) ;

  .innerBox {
    width: 80%;
    aspect-ratio: 1 / 1;
    z-index: 3;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3);
    overflow: hidden;
    div {
      height: 175%;
      width: 175%;
      transform: rotate(-45deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: -15%;
      top: -30%;
    }
  }
  .firstBox {
    width: 90%;
    aspect-ratio: 1 / 1;
    z-index: 2;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)
  }
  .outerBox {
    width: 100%;
    aspect-ratio: 1 / 1;
    z-index: 1;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)

  }
}

.leftSquare {
  position: absolute;  
  bottom: -10%;
  left: 0;
  z-index: -1;
}
.rightSquare {
  position: absolute;
  transform: rotate(180deg);
  top: -30%;
  right: 0;
  z-index: -1;
}

.selectorWrap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  div {
    margin: 0 4px;
    width: 145px;
    height: 20px;
    border-radius: 16px;
    transition: width 0.5s;
    cursor: pointer;
  }
  .selectorBlue {

    background-color: #4C85BA;
  }
  .selectorYellow {
    background-color: #F4C012;
  }
  .selectorRed {
    background-color: #D70E90;
  }
  .active {
    width: 210px;
  }
}
.coursesWrapMobile {
  display: none;
}

@media screen and (max-width: 992px) { 
  .logo {
    max-width: 50%;
    margin-bottom: 0;
  }
  .main {
    padding-bottom: 130px;
    padding-top: 50px;
    h1 {
      font-size: 40px;
    }
  }
  .wrap {
    padding: 32px
  }
  .hWrap {
    .dots {
      width: 80px;
      top: 26px;
      left: -54px;
    }
  }
  .squares {
    display: none;
  }
  .textWrap {
    max-width: 100%;
  }

  .carousel {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }
  .carouselDot {
    width: 12px;
    height: 12px;
    background-color: #CCDEE3;
    border-radius: 100%;
    margin-right: 20px;
  }
  .carouselDotActive {
  background-color: #002853;
  }
  .coursesWrap {
    display: none
  }
  .coursesWrapMobile {
    display: flex;
    flex-direction: column;
  }
  .curso {
    padding-top: 150px;
    h3 {
      margin-top: 220px
    }
  }
  .box {
    max-width: 40%;
  }
}

@media screen and (max-width: 768px) { 
  .box {
    max-width: 60%;
  }
  .curso {
    padding-top: 150px;
  }
}

