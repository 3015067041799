.main {
  position: relative;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;

  h4 {
    color: #002853;
    font-family: 'Raleway';
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  p {
    font-size: 20px;
    font-family: 'Raleway';
    color: #2E2E2E;
    font-weight: 400;
  }
}

.wrap {
  padding-left: 15%;
  padding-top: 50px;
  text-align: left;
  padding-bottom: 400px;
  position: relative;
}

.logo {
  width: 170px;
  margin-bottom: 100px;
}
.squares {
  position: absolute;
  z-index: -1;
  top: 10%;
  left: 10%;
}
.textWrap {
  max-width: 50%;
  margin-bottom: 100px;
  white-space: pre-line;
  p {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #CCDEE3;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 40, 83, 0.5);
      border-radius: 15px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #d3d3d3;
    }
  }
}
.hWrap {
  position: relative;
  text-align: left;
  .dots {
    position: absolute;
    top: -20px;
    left: -100px;
  }
}
.backButton {
  border-radius: 15px;
  background: #002853;
  cursor: pointer;
  color: #ffffff;
  font-family: 'Raleway';
  font-size: 25px;
  font-weight: 700;
  padding: 10px 28px;
}
.box {
  position: absolute;
  right: -10%;
  top: 0%;
  width: 43%;
  height: 43%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg) ;

  .innerBox {
    width: 80%;
    aspect-ratio: 1 / 1;
    z-index: 3;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3);
    overflow: hidden;
    div {
      height: 160%;
      width: 160%;
      transform: rotate(-45deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: -15%;
      top: -30%;
    }
  }
  .firstBox {
    width: 90%;
    aspect-ratio: 1 / 1;
    z-index: 2;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)
  }
  .outerBox {
    width: 100%;
    aspect-ratio: 1 / 1;
    z-index: 1;
    position: absolute;
    border-radius: 15%;
    border: 1px solid rgba(119, 238, 191, 0.3)

  }
}

@media screen and (max-width: 992px) { 
  .logo {
    max-width: 50%;
    margin-bottom: 0;
  }
  .main {
    padding-bottom: 130px;
    padding-top: 50px;
    h1 {
      font-size: 40px;
    }
  }
  .wrap {
    padding: 32px
  }
  .hWrap {
    max-width: 70%;
    .dots {
      width: 80px;
      top: 26px;
      left: -54px;
    }
  }
  .textWrap {
    max-width: 100%;
  }
  .box {
    //display: none;
    z-index: -1;
  }
  .title {
    text-transform: capitalize;
  }
  .squares {
    display: none;
  }
  .box {
    top: -10%;
  }
}
